<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('inventory.requests.instructions')">
        <va-input
          class="gray-text"
          color="info"
          type="textarea"
          :autosize="true"
          :readonly="true"
          :min-rows="3"
          v-model="instructions"
          style="padding-top: 10px; padding-left: 16px; padding-right: 16px;"
        />
      </va-card>
    </div>

    <div class="flex xs12">
      <va-card :title="$t('inventory_requests.new')">
        <actions
          slot="actions"
          crud-links="inventoryRequests"
          :actions="actions"
        />
        <data-form
          :request="inventoryRequest"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Actions");
const DataForm = () => import(/* webpackPrefetch: true */ "./Form");

export default {
  name: "inventory-request-new",
  components: {
    Actions,
    DataForm,
  },
  data() {
    return {
      loading: false,
      actions: ["index"],
      headers: { "Content-Type": "multipart/form-data" },
      inventoryRequest: {
        id: 0,
      },
      instructions: '\t' + this.$t("instructions.inventory.requests"),
    };
  },
    watch: {
    '$i18n.locale'() {
      this.instructions = this.$t('instructions.inventory.requests');
    }
  },
  methods: {
    // FIXME
    routeBuilder() {
      return "inventory/requests/";
    },
    async submit(request) {
      this.loading = true;

      try {
        await this.$http.post(this.routeBuilder(), request, {
          headers: this.headers,
        });
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false;
        return;
      }

      this.loading = false;
      this.showToast(this.$t("notifications.alerts.success"), {
        icon: "fa-check",
        position: "top-right",
      });
      this.$router.push({ name: "inventoryRequestsIndex" });
    },
  },
};
</script>

<style scoped>
.gray-text {
  color: gray;
}
</style>
